import React from 'react'

function HeroForm() {
  return (
    <div className='hero-section'> 
      <div className='hero-content'>
          <h3 className='bookHeadline'>Reserve a Table</h3>
      </div>
    </div>
  )
}

export default HeroForm;